// extracted by mini-css-extract-plugin
export var colors = "'../palette.css'";
export var blue = "#3880ca";
export var grey2 = "#b6a99b";
export var grey3 = "#999";
export var grey4 = "#606060";
export var grey6 = "#111";
export var card = "blog-post-card-module--card--dEO+e";
export var contentHeader = "blog-post-card-module--contentHeader--yFsId";
export var contentCategory = "blog-post-card-module--contentCategory--UTXwm";
export var contentTitle = "blog-post-card-module--contentTitle--fV7dZ";
export var contentSummary = "blog-post-card-module--contentSummary--r95Ty";
export var datePublished = "blog-post-card-module--datePublished--F-WN1";
export var showMore = "blog-post-card-module--showMore--quMIs";
export var showMoreIcon = "blog-post-card-module--showMoreIcon--UKqRT";